import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { NewUser } from "../../../types/form/new-user-data.type";
import { Link, navigate, useIntl } from "gatsby-plugin-intl";
import InlineFormError from "../../form/InlineFormError";
import useCreateNewUserStore from "./useNewUserStore";
import useAllGroups from "../../../hooks/useAllGroups";
import getGroupDisplayName from "../../../utils/getGroupDisplayName";
import { doesUserExist } from "../../../services/userManagementService";
import SwToast from "../../toast/sw-toast";

export interface Option {
  readonly id: string;
  readonly label: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export interface Props extends React.HTMLProps<HTMLDivElement> {
  initialValues: Pick<
    NewUser,
    | "firstName"
    | "lastName"
    | "email"
    | "companyName"
    | "department"
    | "jobTitle"
    | "roles"
    | "sports"
  >;
}

const Step1 = () => {
  const roleSelectRef = useRef<any>(null);
  const sportSelectRef = useRef<any>(null);

  const {
    setFirstName,
    setLastName,
    setEmail,
    setCompanyName,
    setJobTitle,
    setDepartment,
    addRole,
    addSport,
    reset,
  } = useCreateNewUserStore();
  const intl = useIntl();

  const [disableSports, setDisableSports] = useState<boolean>(false);
  const [showFailToast, setShowFailToast] = useState<boolean>(false);
  const { roles, sports } = useAllGroups();

  const allSportsOption: Option = {
    id: "all-sports",
    label: "All sports",
  };

  const sportsOptions: readonly Option[] = [allSportsOption].concat(
    sports?.map((sport) => {
      return {
        id: sport.id || "",
        label: getGroupDisplayName(sport.id, sport.displayName) || "",
      };
    })
  );

  const rolesOptions = roles?.map((role) => {
    return {
      value: role.id,
      label: getGroupDisplayName(role.id, role.displayName),
    };
  });

  const restrictedRoles = [
    process.env.GATSBY_WIPS_GROUP_ID,
    process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID,
    process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID,
  ];

  const isRestrictedRole = (roleValue: string | undefined) => {
    return restrictedRoles.some((role) => role === roleValue);
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<
    Pick<
      NewUser,
      | "firstName"
      | "lastName"
      | "email"
      | "companyName"
      | "department"
      | "jobTitle"
      | "roles"
      | "sports"
    >
  >({
    // validate on submit - button must be enabled for this to work
    mode: "onSubmit",
    // after submit, revalidate form when user completes a field
    reValidateMode: "onBlur",
    defaultValues: {
      sports: [],
    },
  });

  const onSubmit = (
    data: Pick<
      NewUser,
      | "firstName"
      | "lastName"
      | "email"
      | "companyName"
      | "department"
      | "jobTitle"
      | "roles"
      | "sports"
    >
  ) => {
    reset();

    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    if (data.companyName) {
      setCompanyName(data.companyName);
    }
    if (data.jobTitle) {
      setJobTitle(data.jobTitle);
    }
    if (data.department) {
      setDepartment(data.department);
    }
    if (data.roles) {
      data.roles.forEach((role) => {
        addRole(role);
      });
    }

    if (data.sports) {
      // check to add all sports?
      if (data.sports.find((sport) => sport.value === allSportsOption.id)) {
        sports.forEach((sport) => {
          if (sport.id) {
            addSport(sport.id);
          }
        });
      } else {
        // just add selected sports
        data.sports.forEach((sport) => {
          addSport(sport);
        });
      }
    }

    //Check if user exists on tennant
    doesUserExist(data.email).then((response) => {
      const userExists = response.data;
      if (!userExists) {
        navigate("/user-management/invite-user/");
      } else {
        setShowFailToast(true);
      }
    });
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-6">
        <h2>Step 1 of 2</h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="mb-4"
        >
          <fieldset className="border rounded p-3 mb-3">
            <legend>User details</legend>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First name (required)
              </label>
              <input
                className="form-control"
                {...register("firstName", { required: true })}
              />
              {errors.firstName?.type === "required" && (
                <InlineFormError message="First name is required" />
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last name (required)
              </label>
              <input
                className="form-control"
                autoComplete=""
                {...register("lastName", { required: true })}
              />
              {errors.lastName?.type === "required" && (
                <InlineFormError message="Last name is required" />
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email (required)
              </label>
              <input
                type="email"
                className="form-control"
                {...register("email", { required: true })}
              />
              {errors.email?.type === "required" && (
                <InlineFormError message="Email is required" />
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="companyName" className="form-label">
                Company name
              </label>
              <input className="form-control" {...register("companyName")} />
            </div>
            <div className="mb-3">
              <label htmlFor="jobTitle" className="form-label">
                Job title
              </label>
              <input className="form-control" {...register("jobTitle")} />
            </div>
            <div className="mb-3">
              <label htmlFor="department" className="form-label">
                Department
              </label>
              <input className="form-control" {...register("department")} />
            </div>
          </fieldset>
          <fieldset className="mb-3 border rounded p-3">
            <legend>Permissions</legend>
            <div className="mb-3">
              <label htmlFor="roles" className="form-label">
                Roles (required)
              </label>
              <Controller
                control={control}
                name="roles"
                rules={{ required: true }}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Select
                    key="roles"
                    ref={roleSelectRef}
                    menuPosition="fixed"
                    inputId="roles"
                    aria-invalid={error ? "true" : "false"}
                    onChange={(selectedOption: any) => {
                      setValue(
                        "roles",
                        selectedOption
                          ? [
                              {
                                value: selectedOption.value,
                                label: selectedOption.label,
                              },
                            ]
                          : []
                      );
                      if (selectedOption && selectedOption?.value) {
                        let shouldDisable = isRestrictedRole(
                          selectedOption?.value
                        );
                        // Check if the selected role is restricted and update the state
                        setDisableSports(shouldDisable);

                        if (shouldDisable) {
                          setValue("sports", []);
                          (sportSelectRef as any).current.clearValue();
                        }
                      }
                    }}
                    placeholder={"Select role"}
                    options={rolesOptions}
                  />
                )}
              />
              {errors.roles?.type === "required" && (
                <InlineFormError message="At least one role is required" />
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="sports" className="form-label">
                Sports
              </label>
              <Controller
                control={control}
                name="sports"
                rules={{ required: false }}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Select
                    key="roles"
                    ref={sportSelectRef}
                    menuPosition="fixed"
                    isMulti
                    isDisabled={disableSports}
                    inputId="sport"
                    aria-invalid={error ? "true" : "false"}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    onChange={(selectedOptions) => {
                      // all sports is selected
                      if (
                        selectedOptions.find(
                          (option) => option.id === allSportsOption.id
                        )
                      ) {
                        setValue(
                          "sports",
                          sportsOptions.map((sport) => {
                            const { id, label } = sport;
                            return { value: id, label };
                          })
                        );
                      } else {
                        // set selected options
                        setValue(
                          "sports",
                          selectedOptions.map((opt) => {
                            const { id, label } = opt;
                            return { value: id, label };
                          })
                        );
                      }
                    }}
                    placeholder={
                      disableSports
                        ? "This user has access to all sports."
                        : "Select sport"
                    }
                    options={sportsOptions ?? []}
                  />
                )}
              />
            </div>
          </fieldset>
          <button type="submit" className="btn btn-primary">
            Next
          </button>
        </form>
        <div className="wizard-nav__exits mb-4">
          <Link
            className="btn btn-sm btn-outline-secondary me-3"
            to={"/user-management"}
          >
            Cancel
          </Link>
        </div>
      </div>
      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header={intl.formatMessage({
          id: "invite-user.fail",
          defaultMessage: "User already exists",
        })}
        body={intl.formatMessage({
          id: "invite-user.fail.message",
          defaultMessage: "A user with that email address already exists",
        })}
      ></SwToast>
    </section>
  );
};

export default Step1;
